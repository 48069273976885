import React from 'react';
import { useMode } from '../contexts/ModeContext';
import useUntilCorrect from '../hooks/useUntilCorrect';
import AnswerField from '../components/AnswerField';
import ChoicesField from '../components/ChoicesField';
import Location from '../components/Location';
import GameOver from '../components/GameOverUntilCorrect';
import styled from 'styled-components';

const UntilCorrect = ({ keyOfTheCaller }) => {
  const { selectGamePlay } = useMode();

  const {
    weatherData,
    userGuess,
    setUserGuess,
    correct,
    handleGuess,
    playAgain,
    guessCounter
  } = useUntilCorrect();

  let correctAnswer;

  switch (keyOfTheCaller) {
    case 'temp':
      correctAnswer = weatherData ? weatherData.main.temp : null;
      break;
    case 'wind':
      correctAnswer = weatherData ? weatherData.wind.speed : null;
      break;
    case 'humidity':
      correctAnswer = weatherData ? weatherData.main.humidity : null;
      break;
    default:
      return null;
  }

  return (
    <UntilCorrectWrapper>
      {correct ? (
        <GameOver guessCounter={guessCounter} playAgain={playAgain}/>
      ) : (
        <ContentWrapper>
          <Location weatherData={weatherData} />
          {selectGamePlay === 'ChoicesField' ? (
              <ChoicesField
                userGuess={userGuess}
                setUserGuess={setUserGuess}
                handleGuess={handleGuess}
                correctAnswer={correctAnswer}
                keyOfTheCaller={keyOfTheCaller}
              />
            ) : (
              <AnswerField
                userGuess={userGuess}
                setUserGuess={setUserGuess}
                handleGuess={handleGuess}
                correctAnswer={correctAnswer}
              />
            )}
        </ContentWrapper>
      )}
    </UntilCorrectWrapper>
  );
};

const UntilCorrectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default UntilCorrect;
