import React from "react";
import EmailForm from "../components/EmailForm";

function Contact() {
  return (
    <div>
      <EmailForm />
    </div>
  );
}

export default Contact;
