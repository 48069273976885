import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const GameOverBestOf = ({ userScore, guesses, playAgain }) => {
  const navigate = useNavigate();
  const handleMainMenuClick = () => {
    navigate('/');
  };

  return (
    <GameOverWrapper>
      <ProgressBarWrapper>
        {guesses.map((guess, index) => (
          <ProgressCircle
            key={index}
            className={guess === 1 ? 'green' : guess === 2 ? 'red' : ''}
          />
        ))}
      </ProgressBarWrapper>
      <GameOverText>
        <p>{userScore} Correct Answers</p>
        <ButtonWrapper>
          <Button onClick={playAgain}>Play Again</Button>
          <Button onClick={handleMainMenuClick}>Main Menu</Button>
        </ButtonWrapper>
      </GameOverText>
    </GameOverWrapper>
  );
};

const GameOverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding: 20px;
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const ProgressCircle = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: gray;
  margin: 5px;

  &.green {
    background-color: green;
  }

  &.red {
    background-color: red;
  }

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
  }
`;

const GameOverText = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 22px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 481px) {
    flex-direction: row;
  }
`;

const Button = styled.button`
  padding: 0.9rem 1.5rem;
  font-size: 1.7rem;
  border-radius: 25px;
  border: 1.5px solid rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0.1);
  color: #f8f8f8;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  outline: none;
  margin: 5px 0;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
  }

  @media (min-width: 481px) {
    margin: 0 1rem 0 0;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export default GameOverBestOf;

