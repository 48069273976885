import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMode } from '../contexts/ModeContext';
import styled from 'styled-components';
import temperatureImg from '../assets/temp.png';
import humidityImg from '../assets/humidity.png';
import windImg from '../assets/wind.png';
import untilCorrectImg from '../assets/untilCorrect.png';
import bestOfSevenImg from '../assets/bestOfSeven.png';
import suddenDeathImg from '../assets/suddenDeath.png';
import answerImg from '../assets/answer.png';
import choiceImg from '../assets/choice.png';
import logoImg from '../assets/wedrguesr-logo.png';

const Dashboard = () => {
  const { selectedMode, setSelectedMode, setSelectGamePlay } = useMode();
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const navigate = useNavigate();
  const [pendingNavigation, setPendingNavigation] = useState(false);
  const [pendingMode, setPendingMode] = useState(false);

  const handleBoxClick = (option) => {
    setSelectedQuestion(option);
  };

  const handleModeClick = (option) => {
    setSelectedMode(option);
    setPendingMode(true);
  }

  const handleGamePlayClick = (option) => {
    setSelectGamePlay(option);
    setPendingNavigation(true);
  }

  useEffect(() => {
    if (pendingNavigation && selectedQuestion && selectedMode) {
      navigate(`/${selectedQuestion}`);
      setPendingMode(false);
      setPendingNavigation(false);
    }
  }, [pendingNavigation, selectedQuestion, selectedMode, navigate]);

  return (
    <DashboardWrapper>
      <Logo src={logoImg} alt="Wedrguesr Logo" />
      {selectedQuestion === "" ? (
        <>
          <Box onClick={() => handleBoxClick('Humidity')} style={{ backgroundImage: `url(${humidityImg})` }}>
            <BoxTitle>Humidity</BoxTitle>
          </Box>
          <Box onClick={() => handleBoxClick('Wind')} style={{ backgroundImage: `url(${windImg})` }}>
            <BoxTitle>Wind</BoxTitle>
          </Box>
          <Box onClick={() => handleBoxClick('Temp')} style={{ backgroundImage: `url(${temperatureImg})` }}>
            <BoxTitle>Temperature</BoxTitle>
          </Box>
        </>
      ) : pendingMode === false ? (
        <>
          <Box onClick={() => handleModeClick('SuddenDeath')} style={{ backgroundImage: `url(${suddenDeathImg})` }}>
            <BoxTitle>Sudden Death</BoxTitle>
          </Box>
          <Box onClick={() => handleModeClick('BestOfSeven')} style={{ backgroundImage: `url(${bestOfSevenImg})` }}>
            <BoxTitle>Best of Seven</BoxTitle>
          </Box>
          <Box onClick={() => handleModeClick('UntilCorrect')} style={{ backgroundImage: `url(${untilCorrectImg})` }}>
            <BoxTitle>Until Correct</BoxTitle>
          </Box>
        </>
       ) : (
        <>
          <Box onClick={() => handleGamePlayClick('ChoicesField')} style={{ backgroundImage: `url(${choiceImg})` }}>
            <BoxTitle>Select your Choice</BoxTitle>
          </Box>
          <Box onClick={() => handleGamePlayClick('AnswerField')} style={{ backgroundImage: `url(${answerImg})` }}>
            <BoxTitle>Type your Guess</BoxTitle>
          </Box>
        </>
      )}
    </DashboardWrapper>
  );
};

const Logo = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 80px; 
  height: auto;
`;

const DashboardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  @media (orientation: portrait) and (max-aspect-ratio: 1024/1366) {
    flex-direction: column;
  }
  
`;

const Box = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s;
  margin: 60px;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 960px) {
    width: 280px;
    height: 280px;
    margin: 40px;
  }

  @media (max-width: 768px) {
    width: 190px;
    height: 190px;
    margin: 30px;
  }

  @media (max-width: 480px) {
    width: 100px;
    height: 100px;
    margin: 10px;
  }
  
  @media (orientation: landscape) and (max-aspect-ratio: 1024/600) {
    width: 260px;
    height: 260px;
    margin: 30px;
  }
  
  @media (orientation: portrait) and (max-aspect-ratio: 1024/1366) {
    width: 120px;
    height: 120px;
    margin: 30px;
  }
  
  @media (orientation: portrait) and (aspect-ratio: 912/1368) {
    width: 360px;
    height: 360px;
    margin: 40px;
  }

  @media (orientation: portrait) and (aspect-ratio: 1024/1366) {
    width: 360px;
    height: 360px;
    margin: 40px;
  }

  @media (orientation: portrait) and (aspect-ratio: 820/1180) {
    width: 295px;
    height: 295px;
    margin: 50px;
  }

  @media (orientation: portrait) and (aspect-ratio: 768/1024) {
    width: 260px;
    height: 260px;
    margin: 50px;
  }

  @media (orientation: portrait) and (aspect-ratio: 853/1280) {
    width: 350px;
    height: 350px;
    margin: 30px;
  }
`;

const BoxTitle = styled.div`
  text-align: center;
  width: 100%;
  padding: 0.75rem;
  border-radius: 10px;
  background: rgba(46, 123, 199, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  ${Box}:hover & {
    opacity: 1;
  }

  @media (max-width: 768px) {
    padding: 0.5rem;
    margin-top: 10px;
  }

  @media (max-width: 480px) {
    padding: 0.25rem;
    font-size: 0.75rem;
    margin-top: 5px;
  }
`;

export default Dashboard;
