import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';

const apiKey = '2e2e6afe9decbab5a3890edcada0d726'; 

const useWeatherGame = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [city, setCity] = useState('');
  const [userGuess, setUserGuess] = useState('');
  const fetchedCitiesRef = useRef([]);

  const fetchRandomCity = useCallback(async () => {
    try {
      const response = await fetch('/cities.json');
      const cities = await response.json();
      let randomCity;
      do {
        randomCity = cities[Math.floor(Math.random() * cities.length)];
      } while (fetchedCitiesRef.current.includes(randomCity));
      setCity(randomCity);
      fetchedCitiesRef.current = [...fetchedCitiesRef.current, randomCity];
    } catch (error) {
      console.error('Error fetching the cities:', error);
    }
  }, []);

  const fetchWeatherData = useCallback(async (city) => {
    try {
      const randomCityUrl = `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=metric&appid=${apiKey}`;
      const response = await axios.get(randomCityUrl);
      setWeatherData(response.data);
    } catch (error) {
      console.error('Error fetching the weather data:', error);
    }
  }, []);

  const resetFetchedCities = useCallback(() => {
    fetchedCitiesRef.current = [];
  }, []);

  useEffect(() => {
    fetchRandomCity();
  }, [fetchRandomCity]);

  useEffect(() => {
    if (city) {
      fetchWeatherData(city);
    }
  }, [city, fetchWeatherData]);

  return {
    weatherData,
    city,
    userGuess,
    setUserGuess,
    fetchRandomCity,
    fetchWeatherData,
    resetFetchedCities,
  };
};

export default useWeatherGame;