import { useState } from 'react';
import useWeatherGame from './useWeatherGame';

const useBestOfSeven = () => {
  const { weatherData, userGuess, setUserGuess, fetchRandomCity, resetFetchedCities } = useWeatherGame();
  const [gameOver, setGameOver] = useState(false);
  const [userScore, setUserScore] = useState(0);
  const [guesses, setGuesses] = useState(Array(7).fill(0));

  const handleGuess = (correctValue) => {
    const tempGuesses = [...guesses];
    const progress = tempGuesses.findIndex(guess => guess === 0);
    if (parseInt(userGuess) === Math.round(correctValue)) {
      tempGuesses[progress] = 1;
    } else {
      tempGuesses[progress] = 2;
    }
    setGuesses(tempGuesses);
    if (progress === 6) {
      setGameOver(true);
      setUserScore(tempGuesses.filter(guess => guess === 1).length);
    }
    setUserGuess('');
    fetchRandomCity();
  };

  const playAgain = () => {
    setGuesses(Array(7).fill(0));
    fetchRandomCity();
    resetFetchedCities();
    setGameOver(false);
  };

  return {
    weatherData,
    userGuess,
    setUserGuess,
    gameOver,
    userScore,
    guesses,
    handleGuess,
    playAgain,
  };
};

export default useBestOfSeven;
