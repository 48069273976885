import React from 'react';
import styled from 'styled-components';
import sunsetImage from '../assets/sunset.jpg';

const Background = ({ children }) => {
  return (
    <BackgroundWrapper>
      {children}
    </BackgroundWrapper>
  );
};

const BackgroundWrapper = styled.div`
  background: url(${sunsetImage}) no-repeat center center/cover;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 1200px) {
    background-size: cover;
  }

  @media (max-width: 992px) {
    background-size: cover;
  }

  @media (max-width: 768px) {
    background-size: cover;
  }

  @media (max-width: 576px) {
    background-size: cover;
  }

  @media (max-width: 360px) {
    background-size: cover;
  }
`;

export default Background;
