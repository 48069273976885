import React from 'react';
import styled from 'styled-components';
import { useMode } from '../contexts/ModeContext';
import Location from '../components/Location';
import AnswerField from '../components/AnswerField';
import ChoicesField from '../components/ChoicesField';
import ProgressBar from '../components/ProgressBar';
import GameOver from '../components/GameOverBestOf';
import useBestOfSeven from '../hooks/useBestOfSeven';

const BestOfSeven = ({keyOfTheCaller}) => {
  const { selectGamePlay } = useMode();
  
  const {
    weatherData,
    userGuess,
    setUserGuess,
    gameOver,
    userScore,
    guesses,
    handleGuess,
    playAgain,
  } = useBestOfSeven();

  let correctAnswer;

  switch(keyOfTheCaller) {
    case 'temp':
        correctAnswer = weatherData ? weatherData.main.temp : null;
        break;
    case 'wind':
        correctAnswer = weatherData ? weatherData.wind.speed : null;
        break;
    case 'humidity':
        correctAnswer = weatherData ? weatherData.main.humidity : null;
        break;
    default:
        return null;
  }

  return (
    <BestOfSevenWrapper>
      {gameOver ? (
        <GameOverWrapper>
          <GameOver 
            userScore={userScore}
            guesses={guesses}
            playAgain={playAgain}
          />
        </GameOverWrapper>
      ) : (
        <>
          <LocationWrapper>
            <Location weatherData={weatherData} />
          </LocationWrapper>
          <AnswerFieldWrapper>
          {selectGamePlay === 'ChoicesField' ? (
              <ChoicesField
                userGuess={userGuess}
                setUserGuess={setUserGuess}
                handleGuess={handleGuess}
                correctAnswer={correctAnswer}
                keyOfTheCaller={keyOfTheCaller}
              />
            ) : (
              <AnswerField
                userGuess={userGuess}
                setUserGuess={setUserGuess}
                handleGuess={handleGuess}
                correctAnswer={correctAnswer}
              />
            )}
          </AnswerFieldWrapper>
          <ProgressBarWrapper>
            <ProgressBar guesses={guesses} />
          </ProgressBarWrapper>
        </>
      )}
    </BestOfSevenWrapper>
  );
};

const BestOfSevenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

const LocationWrapper = styled.div`
  margin-bottom: 20px;
`;

const AnswerFieldWrapper = styled.div`
  margin-bottom: 20px;
`;

const ProgressBarWrapper = styled.div`
  margin-bottom: 20px;
`;

const GameOverWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  color: #fff;
`;

export default BestOfSeven;

