import React from 'react';
import Background from '../components/Background';
import BestOfSeven from '../modes/BestOfSeven';
import SuddenDeath from '../modes/SuddenDeath';
import UntilCorrect from '../modes/UntilCorrect';
import { useMode } from '../contexts/ModeContext';

const Temp = () => {
  const { selectedMode } = useMode();

  const ModeComponent = {
    BestOfSeven: BestOfSeven,
    SuddenDeath: SuddenDeath,
    UntilCorrect: UntilCorrect,
  }[selectedMode];

  if (!ModeComponent) {
    return null;
  }

  return (
    <Background>
      <ModeComponent keyOfTheCaller={'temp'} />
    </Background>
  );
};

export default Temp;
