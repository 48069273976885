import React from 'react';
import styled from 'styled-components';

const Location = ({ weatherData }) => {
  return (
    <LocationWrapper>
      {weatherData && weatherData.name && weatherData.sys && (
        <h2>{weatherData.name}, {weatherData.sys.country}</h2>
      )}
    </LocationWrapper>
  );
};

const LocationWrapper = styled.div`
  text-align: center;
  font-size: 6rem;
  color: white;

  @media (max-width: 1200px) {
    font-size: 5rem;
  }

  @media (max-width: 992px) {
    font-size: 4rem;
  }

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 576px) {
    font-size: 2rem;
  }

  @media (max-width: 360px) {
    font-size: 1.5rem;
  }
`;

export default Location;


