import React, { createContext, useContext, useState } from 'react';

const ModeContext = createContext();

export const ModeProvider = ({ children }) => {
  const [selectedMode, setSelectedMode] = useState("");
  const [selectGamePlay, setSelectGamePlay] = useState("");

  return (
    <ModeContext.Provider value={{ selectedMode, setSelectedMode, selectGamePlay, setSelectGamePlay }}>
      {children}
    </ModeContext.Provider>
  );
};

export const useMode = () => useContext(ModeContext);
