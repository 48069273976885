import React from 'react';
import styled from 'styled-components';
import { useMode } from '../contexts/ModeContext';
import useSuddenDeath from '../hooks/useSuddenDeath';
import AnswerField from '../components/AnswerField';
import ChoicesField from '../components/ChoicesField';
import GameOver from '../components/GameOverSudden';
import Location from '../components/Location';

const SuddenDeath = ({ keyOfTheCaller }) => {
  const { selectGamePlay } = useMode();

  const {
    weatherData,
    userGuess,
    setUserGuess,
    gameOver,
    userScore,
    handleGuess,
    playAgain,
  } = useSuddenDeath();

  let correctAnswer;

  switch (keyOfTheCaller) {
    case 'temp':
      correctAnswer = weatherData ? weatherData.main.temp : null;
      break;
    case 'wind':
      correctAnswer = weatherData ? weatherData.wind.speed : null;
      break;
    case 'humidity':
      correctAnswer = weatherData ? weatherData.main.humidity : null;
      break;
    default:
      return null;
  }

  return (
    <SuddenDeathWrapper>
      {gameOver ? (
        <GameOver userScore={userScore} playAgain={playAgain} />
      ) : (
        <ContentWrapper>
          <Location weatherData={weatherData} />
          {selectGamePlay === 'ChoicesField' ? (
            <ChoicesField
              userGuess={userGuess}
              setUserGuess={setUserGuess}
              handleGuess={handleGuess}
              correctAnswer={correctAnswer}
              keyOfTheCaller={keyOfTheCaller}
            />
          ) : (
            <AnswerField
              userGuess={userGuess}
              setUserGuess={setUserGuess}
              handleGuess={handleGuess}
              correctAnswer={correctAnswer}
            />
          )}
        </ContentWrapper>
      )}
    </SuddenDeathWrapper>
  );
};

const SuddenDeathWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default SuddenDeath;
