import React from "react";
import styled from "styled-components";

function PrivacyPolicy() {
  return (
    <PrivacyContainer>
      <PrivacyText>
        <PrivacyTitle>Privacy Policy</PrivacyTitle>
        <PrivacySection>
          <h2>Introduction</h2>
          <p>
            We are not interested in collecting any personal information. We
            believe such information is yours and yours alone. We do not store
            or transmit your personal details, nor do we include any advertising
            or analytics software that talks to third parties.
          </p>
        </PrivacySection>

        <PrivacySection>
          <h2>What Information Do We Collect?</h2>
          <p>
            Weatherguessr does not collect any personal information or connect to the
            internet. This is a simple game solely exists on your device.
          </p>
        </PrivacySection>
      </PrivacyText>
    </PrivacyContainer>
  );
}

const PrivacyContainer = styled.div`
  max-width: 80%;
  margin: 0 auto;
  padding: 8%;
`;

const PrivacyText = styled.div`
  font-style: ibm;
`;

const PrivacyTitle = styled.h1`
  font-size: 32px;
  color: #444;
  text-align: center;
  margin-bottom: 6%;
`;

const PrivacySection = styled.div`
  margin-bottom: 8%;

  h2 {
    font-size: 24px;
    color: #444;
    margin-bottom: 3%;
  }

  p {
    font-size: 18px;
    color: #444;
    line-height: 1.4;
  }
`;

export default PrivacyPolicy;
