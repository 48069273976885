import React from 'react';
import Dashboard from '../components/Dashboard';
import Background from '../components/Background';

const Home = () => {
  return (
    <Background>
      <Dashboard />
    </Background>
  );
};

export default Home;
