import React from 'react';
import styled from 'styled-components';

const AnswerField = ({ userGuess, setUserGuess, handleGuess, correctAnswer }) => {
  return (
    <GuessWrapper>
      <InputText
        value={userGuess}
        onChange={event => setUserGuess(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && correctAnswer !== undefined) handleGuess(correctAnswer);
        }}
        placeholder="Enter your guess"
      />
    </GuessWrapper>
  );
};

const GuessWrapper = styled.div`
  text-align: center;
  padding: 1rem;
`;

const InputText = styled.input`
  padding: 0.7rem 1.5rem;
  font-size: 1.2rem;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0.1);
  color: #f8f8f8;
  width: 80%;  
  max-width: 400px;

  @media (max-width: 1200px) {
    font-size: 1.1rem;
    padding: 0.6rem 1.2rem;
  }

  @media (max-width: 992px) {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
  }

  @media (max-width: 576px) {
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
  }

  @media (max-width: 360px) {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
  }
`;

export default AnswerField;
