import { useState } from 'react';
import useWeatherGame from './useWeatherGame';

const useSuddenDeath = () => {
  const { weatherData, userGuess, setUserGuess, fetchRandomCity, resetFetchedCities } = useWeatherGame();
  const [gameOver, setGameOver] = useState(false);
  const [userScore, setUserScore] = useState(0);

  const handleGuess = (correctValue) => {
    if (parseInt(userGuess) !== Math.round(correctValue)) {
      setUserGuess('');
      setGameOver(true);
    } else {
      setUserScore(userScore + 1);
      setUserGuess('');
      fetchRandomCity();
    }
  };

  const playAgain = () => {
    fetchRandomCity();
    setGameOver(false);
    resetFetchedCities();
    setUserScore(0);
  };

  return {
    weatherData,
    userGuess,
    setUserGuess,
    gameOver,
    userScore,
    handleGuess,
    playAgain,
  };
};

export default useSuddenDeath;
