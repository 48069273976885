import { useState } from 'react';
import useWeatherGame from './useWeatherGame';

const useUntilCorrect = () => {
  const { weatherData, userGuess, setUserGuess, fetchRandomCity, resetFetchedCities } = useWeatherGame();
  const [correct, setCorrect] = useState(false);
  const [guessCounter, setGuessCounter] = useState(1);
  
  const handleGuess = (correctValue) => {
    if (parseInt(userGuess) === Math.round(correctValue)) {
      setCorrect(true);
    } else {
      setGuessCounter(guessCounter + 1);
      setUserGuess('');
    }
  };

  const playAgain = () => {
    fetchRandomCity();
    setUserGuess('');
    resetFetchedCities();
    setCorrect(false);
  };

  return {
    weatherData,
    userGuess,
    setUserGuess,
    correct,
    handleGuess,
    playAgain,
    guessCounter
  };
};

export default useUntilCorrect;
