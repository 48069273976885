import React, { useEffect } from 'react';
import styled from 'styled-components';

const ChoicesField = ({ userGuess, setUserGuess, handleGuess, correctAnswer, keyOfTheCaller }) => {
  const generateChoices = () => {
    const incorrectChoices = generateIncorrectChoices(correctAnswer);
    const shuffledChoices = shuffleChoices([correctAnswer, ...incorrectChoices]);
    return shuffledChoices.map(choice => Math.round(choice));
  };

  const generateIncorrectChoices = (correctAnswer) => {
    const isWind = keyOfTheCaller === 'wind';
    const isHumidity = keyOfTheCaller === 'humidity';
    let choice1, choice2;
  
    const generateUniqueChoice = (baseValue, existingChoices) => {
      let newChoice;
      do {
        const random = Math.floor(Math.random() * 31) - 15;
        newChoice = Math.round(baseValue + random);
        if (isHumidity) {
          newChoice = Math.min(100, Math.max(0, newChoice));
        } else if (isWind) {
          newChoice = Math.max(0, newChoice);
        }
      } while (existingChoices.includes(newChoice));
      return newChoice;
    };
  
    if (isWind || isHumidity) {
      choice1 = generateUniqueChoice(correctAnswer, [Math.round(correctAnswer)]);
      choice2 = generateUniqueChoice(correctAnswer, [Math.round(correctAnswer), choice1]);
    } else { // temperature
      choice1 = generateUniqueChoice(correctAnswer, [Math.round(correctAnswer)]);
      choice2 = generateUniqueChoice(correctAnswer, [Math.round(correctAnswer), choice1]);
    }
  
    return [choice1, choice2];
  };

  const shuffleChoices = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  useEffect(() => {
    if (userGuess !== '') {
      handleGuess(correctAnswer);
    }
  }, [userGuess, correctAnswer, handleGuess]);

  const handleClick = (choice) => {
    setUserGuess(String(choice));
  };

  return (
    <ChoicesWrapper>
      {generateChoices().map((choice, index) => (
        <ChoiceButton key={index} onClick={() => handleClick(choice)}>
          {choice}
        </ChoiceButton>
      ))}
    </ChoicesWrapper>
  );
};

const ChoicesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 1rem;
`;

const ChoiceButton = styled.button`
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 25px;
  border: none;
  background-color: rgba(240, 240, 240, 0.5);
  color: #333;
  cursor: pointer;
  width: 150px;
  height: 60px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  
  &:hover {
    background-color: #e0e0e0;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    width: 120px;
    height: 50px;
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    width: 100px;
    height: 40px;
    font-size: 0.9rem;
  }
`;

export default ChoicesField;
