import React from 'react';
import styled from 'styled-components';

const ProgressBar = ({ guesses }) => {
  return (
    <ProgressBarWrapper>
      {guesses.map((guess, index) => (
        <ProgressCircle
          key={index}
          className={guess === 1 ? 'green' : guess === 2 ? 'red' : ''}
        />
      ))}
    </ProgressBarWrapper>
  );
};

const ProgressBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ProgressCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: gray;
  margin: 0 5px;

  &.green {
    background-color: green;
  }

  &.red {
    background-color: red;
  }

  @media (max-width: 1200px) {
    width: 45px;
    height: 45px;
    margin: 0 4px;
  }

  @media (max-width: 992px) {
    width: 40px;
    height: 40px;
    margin: 0 3px;
  }

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    margin: 0 2px;
  }

  @media (max-width: 576px) {
    width: 30px;
    height: 30px;
    margin: 0 1px;
  }

  @media (max-width: 360px) {
    width: 25px;
    height: 25px;
    margin: 0 1px;
  }
`;

export default ProgressBar;
