import React from 'react';
import { Route, Routes } from "react-router-dom";
import { ModeProvider } from './contexts/ModeContext';
import Home from "./pages/Home";
import Humidity from "./pages/Humidity";
import Temp from "./pages/Temp";
import Wind from "./pages/Wind"
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';

function App() {
  return (
    <ModeProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Temp" element={<Temp />} />
        <Route path="/Humidity" element={<Humidity />} />
        <Route path="/Wind" element={<Wind />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Privacy" element={<Privacy />} />
    </Routes>
    </ModeProvider>
  )
}

export default App;
